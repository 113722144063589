<template>
  <div class="misc-wrapper">
    <div class="header">
      <logo></logo>
    </div>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('unauthorized.title') }}
        </h2>
        <p class="mb-2">
          {{ $t('unauthorized.description') }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="loginRoute()"
          >{{ $t('back-home') }}</b-button
        ><br />
        <b-link @click="logout">
          <span>&nbsp;{{ $t('Logout') }}</span>
        </b-link>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue';
import Logo from '../components/Logo.vue';
import store from '@/store/index';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import { userLogout } from '@/auth/utils';
import { initialAbility } from '@/libs/acl/config';

export default {
  components: {
    BLink,
    BImg,
    BButton,
    Logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not_authorized.svg'),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not_authorized.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    loginRoute() {
      const user = this.$store.state.auth.userData;
      return getHomeRouteForLoggedInUser(user ? user.role : null);
    },
    logout() {
      userLogout();
      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.header {
  position: absolute;
  top: 20px;
  left: 0;
}
</style>
